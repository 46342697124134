import { grey, red } from '@mui/material/colors';
import { createTheme, darken, lighten } from '@mui/material/styles';

export const primaryColor = '#585cb0';
export const secondaryColor = '#b43c8d'; //#e52e80 or #b43c8d or #f155bd

// A custom theme for this app
const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            light: '#55A1FF',
            main: primaryColor,
            dark: '#53568c',
            contrastText: '#fff',
        },
        secondary: {
            light: lighten(secondaryColor, 0.45),
            main: secondaryColor,
            dark: darken(secondaryColor, 0.45),
            contrastText: '#fff',
        },
        error: {
            main: red.A400,
        },
    },
});

export default theme;

export const PANEL_GRID_COLS_STYLES = {
    '& .grid--header': {
        backgroundColor: grey[100],
    },
    '& .grid--header-start': {
        backgroundColor: grey[100],
        borderTopLeftRadius: 24,
    },
    '& .grid--header-end': {
        backgroundColor: grey[100],
        borderTopRightRadius: 24,
    },
}