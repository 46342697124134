import React from "react";
import LoadingPanel from "./LoadingPanel";
import Panel from "./Panel";

const SuspenseLoading = () => {

    return (
        <Panel variant="colored" style={{ display: 'flex', justifyContent: 'center', height: 200, top: '50%', minWidth: 300, left: '50%', transform: 'translateY(-50%) translateX(-50%)', position: 'absolute' }}>
            <LoadingPanel msg={"Un momento por favor, estamos cargando los componentes"} />
        </Panel>
    )
};

export default SuspenseLoading;