import Qs from 'qs';

export const login = (username, password, rememberMe = false) => ({
    url: 'admin/login',
    method: 'POST',
    data: Qs.stringify({
        'username': username,
        'password': password,
        'remember': rememberMe
    }),
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
})

export const logout = () => ({
    url: 'admin/logout',
    method: 'POST',
})

export const consultarCuenta = () => ({
    url: `admin/cuenta`,
    method: 'GET',
})