import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';

import moment from 'moment';
import isFunction from 'lodash/isFunction';
import { isDate } from 'lodash';
import { lazy } from 'react';

export const toComboValues = (records, fieldText = "nombre", fieldValue = "id") => records && records.length > 0 ? records.map(r => ({ value: r[fieldValue], text: r[fieldText] })) : [];

export const toComboValuesRenderer = (records, rendererText, disabled, fieldValue = "id") => records && records.length > 0 ? records.map(r => ({ value: r[fieldValue], text: rendererText && isFunction(rendererText) ? rendererText(r) : '<No renderer function>', disabled: disabled && isFunction(disabled) ? disabled(r) : false })) : [];

export const toComboValuesKeyValue = (data) => {
    var options = [];
    Object.entries(data).forEach(entry => {
        let key = entry[0];
        let value = entry[1];
        options.push({ value: key, text: value });
    });
    return options;
}

export function convertMSToObject(milliseconds) {
    var day, hour, minute, seconds;
    seconds = Math.floor(milliseconds / 1000);
    minute = Math.floor(seconds / 60);
    seconds = seconds % 60;
    hour = Math.floor(minute / 60);
    minute = minute % 60;
    day = Math.floor(hour / 24);
    hour = hour % 24;
    return {
        day: day,
        hour: hour,
        minute: minute,
        seconds: seconds
    };
}

export function calculateWeekData(date) {
    const _date = moment(makeJSDateObject(date));
    const week = _date.week();
    const start = _date.startOf('week').toDate();
    const end = _date.endOf('week').toDate();
    return ({
        week,
        start,
        end,
    });
}

export function makeJSDateObject(date, props = { returnMoment: false, format: 'YYYY-MM-DDTHH:mm:ss' }) {
    if (isString(date)) {
        return props.returnMoment ? moment(date, props.format) : moment(date, props.format).toDate();
    }

    if (moment.isMoment(date)) {
        return props.returnMoment ? date : date.clone().toDate();
    }

    if (date instanceof Date) {
        return props.returnMoment ? moment(date) : new Date(date.getTime());
    }

    return date; // handle case with invalid input
}

export function trimObj(obj) {
    if (isNil(obj)) return obj;
    if (!Array.isArray(obj) && typeof obj != 'object') return obj;
    return Object.keys(obj).reduce(function (acc, key) {
        acc[key.trim()] = typeof obj[key] == 'string' && !isNil(obj[key]) ? obj[key].trim() : trimObj(obj[key]);
        return acc;
    }, Array.isArray(obj) ? [] : {});
}

export const img = (path) => `${process.env.PUBLIC_URL}${path}`;

export function onlyNumbers(value) {
    return value.replace(/[^0-9]/g, '');
}

export function convertToNumber(value) {
    if (isString(value) && !isEmpty(value)) {
        if (value.includes('.')) { //Lo convertimos a un flotante si tiene un punto
            return parseFloat(value.replace(/[^0-9-.]/g, ''));
        } else {
            return parseInt(onlyNumbers(value), 10);
        }
    }
    return value;
}

const _isBlank = (v) => !isNil(v) && v !== '';

export function hasChanged(a, b, props) {
    const _a = pickBy(props ? pick(a, props) : a, _isBlank);
    const _b = pickBy(props ? pick(b, props) : b, _isBlank);

    return JSON.stringify(_a, Object.keys(_a).sort()) !== JSON.stringify(_b, Object.keys(_b).sort());
}

export function calculateDateValue(value, format) {
    return value ? format ? moment(value, format) : moment(value) : null;
}

export function calculateValueFromEvent(event) {
    if (isNil(event)) return event;
    //Revisamos si es un date
    if (isDate(event)) {
        return moment(event).isValid() ? moment(event).format() : null;
    } else if (moment.isMoment(event)) {
        return event.isValid() ? event.format() : null;
    }
    return event.target.type === 'checkbox' ? event.target.checked : event.target.value
}

export const calcultateValueFromRecords = (value, records, field = 'id') => {
    if (value === '' && records.length !== 0) {
        return records[0][field];
    } else if (value !== '' && records.length !== 0) {
        const record = records.find(r => r[field] === value) || records[0];
        return record[field];
    } else {
        return '';
    }
}

//Funciones para ordenamiento local de registros


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

/**
 * Comparador por defecto que utiliza < y >
 * 
 * @param {*} a Un valor a comparar
 * @param {*} b Otro valor a comparar
 * @returns 
 */
const defaultComp = function (a, b) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}

/**
 * 
 * @param {*} sortBy Un string, un objeto o un array con la especificación del
 * ordenamiento.
 * Si es un string, es el nombre del campo y se ordena de forma ascendente
 * Si es un objeto, es un ordenamiento por un solo campo
 * Si es un arreglo, es un ordenamiento por varios campos
 * El objeto de especificación de ordenamiento tiene:
 *  key (obligatorio) - Nombre del campo
 *  dir (opcional) - 'asc' para ascendente (por defecto), 'desc' para descendiente: <pre>
 *  comparator (opcional) - función de comparación si no sirve < y > para el tipo de campo
   const sortBy = [
    { key: "age"},
    { key: "salary", dir: "desc" },
    { key: "salary", comparator: <custom comparator function>}];
 * </pre>
 * @returns Una función comparadora de dos valores
 */
const createComparator = (sortBy) => (a, b) => {
    if (typeof sortBy === "string") {
        sortBy = [{ key: sortBy }];
    }
    if (!Array.isArray(sortBy)) {
        sortBy = [sortBy];
    }
    for (let i = 0; i < sortBy.length; i++) {
        const key = sortBy[i].key;
        const direction = sortBy[i].direction === "desc" ? -1 : 1;
        const comp = sortBy[i].comparator ? sortBy[i].comparator : defaultComp;
        if (comp(a[key], b[key]) < 0) return -1 * direction;
        if (comp(a[key], b[key]) > 0) return 1 * direction;
    }
    return 0;
}

export function sortObjectArray(array, sortBy) {
    return [...array].sort(createComparator(sortBy));
}

export const lazyWithRetry = (componentImport) =>
    lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.localStorage.getItem(
                'page-has-been-force-refreshed'
            ) || 'false'
        );

        try {
            const component = await componentImport();

            window.localStorage.setItem(
                'page-has-been-force-refreshed',
                'false'
            );

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                // Assuming that the user is not on the latest version of the application.
                // Let's refresh the page immediately.
                window.localStorage.setItem(
                    'page-has-been-force-refreshed',
                    'true'
                );
                return window.location.reload();
            }

            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let's let the application crash and raise the error.
            throw error;
        }
    });