import './App.css';

import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";

import { createContext, Suspense, useEffect, useReducer, useState } from 'react';
import moment from 'moment';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import LoadingDialog from './components/LoadingDialog';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useApi from './api';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import 'moment/locale/es';
import { consultarCuenta } from './api/cuenta';
import { isNumber, isObject } from 'lodash';

// import MenuAppBar from './components/app/AppBar';
// import Login from './routes/Login';
// import Home from './routes/Home';
// import Clientes from './pages/clientes/Clientes';
// import Modulos from './pages/modulos/Modulos';
// import Tarifas from './pages/tarifas/Tarifas';
import { lazyWithRetry } from './components/Utils';
import SuspenseLoading from './components/SuspenseLoading';

const MenuAppBar = lazyWithRetry(() => import('./components/app/AppBar'));
const Login = lazyWithRetry(() => import('./routes/Login'));
const Home = lazyWithRetry(() => import('./routes/Home'));
const Clientes = lazyWithRetry(() => import('./pages/clientes/Clientes'));
const Modulos = lazyWithRetry(() => import('./pages/modulos/Modulos'));
const Tarifas = lazyWithRetry(() => import('./pages/tarifas/Tarifas'));

const cuentaInitialState = {
    loaded: false,
    loggedIn: false,
    needsLogin: false,
    permisos: [],
}

export const APP_RELOAD_SESSION = 'needUpdateSession';
export const APP_RELOAD_CLIENTE = 'reloadCliente';

//Se recomienda usar valores numéricos para "escuchar" el incremento del valor
const appInitialState = {
    [APP_RELOAD_SESSION]: 0,
    [APP_RELOAD_CLIENTE]: 0,
}

const appContextInitialState = {
    cuenta: {
        ...cuentaInitialState,
    },
    app: {
        ...appInitialState,
    },
    notificacion: {},
    notificaciones: [],
    notificar: () => { },
    notificarInfo: () => { },
    notificarError: () => { },
    notificarWarn: () => { },
    actualizaApp: () => { },
    actualizaCuenta: () => { },
    tieneCuentaPermisos: () => { },
    wipeAllData: () => { },
    showLoadingDialog: () => { },
}

export const AppContext = createContext(appContextInitialState);

const MainApp = () => {

    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case 'UPDATE_APP':
                return { ...state, app: { ...state.app, ...action.payload } };
            case 'UPDATE_CUENTA':
                return { ...state, cuenta: { ...state.cuenta, ...action.payload } };
            case 'CLEAR_APP':
                return { ...state, app: { ...appContextInitialState.app } };
            case 'CLEAR_CUENTA':
                return { ...state, cuenta: { ...appContextInitialState.cuenta } };
            default:
                return state;
        }
    }, appContextInitialState);

    const { app, cuenta } = state;
    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [showLoading, setShowLoading] = useState(false);

    const [{ data: cuentaData, loading: loadingCuenta, error: cuentaError }, cuentaApi] = useApi();

    const loggedIn = cuenta.loggedIn || false;

    useEffect(() => {
        console.log("MainApp...");
        // const browser = detect();
        // console.log(browser);
        //Ajustes para moment GLOBAL...
        moment.updateLocale('es', { week: { dow: 0 } }) //La semana va de dom-sab
    }, [])

    useEffect(() => {
        //Se venció la sesion... a firmarse de nuevo
        if (app.needUpdateSession > 0) {
            wipeAllData();
            // history.replace("/");
            // setCuenta(cuentaInitialState);
            //actualizaApp({ needUpdateSession: false });
        }
    }, [app.needUpdateSession]);

    useEffect(() => {
        if (cuenta.loaded !== true && loadingCuenta !== true && cuenta.needsLogin === false) {
            console.log("Consultando cuenta...");
            cuentaApi(consultarCuenta());
        }
    }, [cuenta]);

    useEffect(() => {
        if (cuentaData) {
            // console.log("cuentaData", cuentaData);
            actualizaCuenta({
                ...cuenta,
                ...cuentaData,
                loaded: true,
                loggedIn: true,
            })
        }
    }, [cuentaData]);

    useEffect(() => {
        if (cuentaError && cuentaError.response) {
            if (cuentaError.response.status === 401) {
                //wipeAllData();
                actualizaCuenta({
                    ...cuenta,
                    needsLogin: true,
                });
                navigate("/", { replace: true });
            }
        }
    }, [cuentaError]);

    //Funciones para el AppContext
    const notificar = (msg, options = { variant: 'default' }) => {
        let newOptions = { ...options };
        if (options.variant === 'error' || options.closeable === true) {
            newOptions = { ...newOptions, action: key => (<IconButton color="inherit" onClick={() => { closeSnackbar(key) }}><CloseIcon /></IconButton>) };
        }
        return msg ? enqueueSnackbar(msg, newOptions) : null;
    }

    const notificarError = (msg, options) => {
        return notificar(msg, { ...options, variant: 'error' });
    }

    const notificarInfo = (msg, options) => {
        return notificar(msg, { ...options, variant: 'info' });
    }

    const notificarWarn = (msg, options) => {
        return notificar(msg, { ...options, variant: 'warning' });
    }

    const actualizaApp = (key, value) => {
        // La ides es incrementar el valor de la llave en uno, si no se especifica un valor
        const currentValue = app[key];
        const nextValue = isNil(value) ? (isNumber(currentValue) ? (currentValue + 1) : value) : value;
        const newState = isObject(value) ? value : { [key]: nextValue };
        dispatch({
            type: 'UPDATE_APP',
            payload: {
                ...app,
                ...newState,
            }
        });
    }

    const actualizaCuenta = (value) => {
        dispatch({
            type: isNil(value) ? 'CLEAR_CUENTA' : 'UPDATE_CUENTA',
            payload: {
                ...cuenta,
                ...value,
            }
        });
    }

    const tieneCuentaPermisos = (permisos = []) => {
        if (!isArray(permisos)) {
            permisos = [permisos];
        }
        if (permisos.length > 0 && cuenta && cuenta.permisos && cuenta.permisos.length > 0) {
            const cuentaPermisos = cuenta.permisos.filter(p => permisos.includes(p.codigo) && p.vista === true);
            return cuentaPermisos.length > 0;
        }
        return false;
    }

    const wipeAllData = () => {
        actualizaCuenta();
        actualizaApp();
    }

    const showLoadingDialog = open => {
        setShowLoading(open);
    }

    //Funciones Locales...
    const canGoToIndex = () => {
        return loggedIn === true;
    }

    const showLogin = () => {
        //const noFirmadoNoCargandoCuenta = loggedIn === false && loadingCuenta !== true;
        return cuenta.needsLogin;
    }


    return (

        <AppContext.Provider value={{
            ...state,
            notificar,
            notificarInfo,
            notificarError,
            notificarWarn,
            actualizaApp,
            actualizaCuenta,
            tieneCuentaPermisos,
            wipeAllData,
            showLoadingDialog,
        }}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='es'>
                <div>
                    <Routes>
                        {showLogin() &&
                            <Route path="/" element={<Suspense fallback={<SuspenseLoading />}><Login /></Suspense>} />
                        }
                        {canGoToIndex() &&
                            <>
                                <Route path="/" element={<Suspense fallback={<SuspenseLoading />}><MenuAppBar /></Suspense>}>
                                    <Route index element={<Suspense fallback={<SuspenseLoading />}><Home /></Suspense>} />
                                    <Route path="/clientes/*" element={<Suspense fallback={<SuspenseLoading />}><Clientes /></Suspense>} />
                                    <Route path="/tarifas/*" element={<Suspense fallback={<SuspenseLoading />}><Tarifas /></Suspense>} />
                                    <Route path="/modulos/*" element={<Suspense fallback={<SuspenseLoading />}><Modulos /></Suspense>} />
                                </Route>
                            </>
                        }
                    </Routes>
                </div>
            </LocalizationProvider>
            <LoadingDialog open={showLoading} />
        </AppContext.Provider>

    )
}

const App = () => {
    return (
        <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Routes>
                    <Route path="/*" element={<MainApp />} />
                </Routes>
            </BrowserRouter>
        </SnackbarProvider>
    );
}

export default App;
