//import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Box, lighten, Paper, Typography } from "@mui/material";
import { isString } from "lodash";

// const PaperStyled = styled(Paper)(
//     ({ theme }) => `
//     padding: ${theme.spacing(2)};
//     border-radius: ${theme.spacing(3)};
//     box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
//     `
// )

const Panel = ({ ...props }) => {
    const { gutterBottom = false, sx, title, children, variant, titleActions, ...others } = props;
    const theme = useTheme();

    return (
        <Paper sx={{ backgroundColor: variant === 'colored' ? lighten(theme.palette.primary.main, 0.88) : null, mb: gutterBottom ? 2 : 0, p: 2, borderRadius: 6, boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px', ...sx }} {...others}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                {title && isString(title) && <Typography component={"div"} sx={{ fontWeight: 450 }}>{title}</Typography>}
                <Box sx={{ flexGrow: 1 }} />
                {titleActions}
            </Box>
            {children}
        </Paper>
    )
}

export default Panel;