import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

// Material UI Components
import { CircularProgress, Typography } from '@mui/material';

const defaultMsg = <>
    Espera un momento por favor...
</>

class LoadingPanel extends Component {
    render() {
        let { msg } = this.props;
        return (
            <div style={{ display: 'flex', justifyContent: 'center', height: '100%', padding: 16 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CircularProgress sx={{ flex: '1 0 auto' }} thickness={6} />
                    <div style={{ marginLeft: 18 }}>
                        <Typography variant="subtitle1">
                            {!isEmpty(msg) ? msg : defaultMsg}
                        </Typography>
                    </div>
                </div>
            </div>
        )
    }
}

LoadingPanel.propTypes = {
    msg: PropTypes.string,
};

LoadingPanel.displayName = 'LoadingPanel';

export default LoadingPanel;