import React from 'react';
import './index.css';
import App from './App';
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import theme from './theme';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <GlobalStyles
      styles={{
        body: { backgroundColor: '#e4e4f5' },
        a: { color: '#000000DE', textDecoration: 'none' },
        'a:hover': { textDecoration: 'underline' },
      }}
    />
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ThemeProvider>
);