import React from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import Dialog from '@mui/material/Dialog';
// import MuiDialogContent from '@mui/material/DialogContent';

// Material UI Styles
import LoadingPanel from './LoadingPanel';
// import styled from '@emotion/styled/macro';
import DialogContent from '@mui/material/DialogContent';



// const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
//     root: {
//         '&:first-child': {
//             paddingTop: 10,
//         },
//     }
// }));

const LoadingDialog = ({ ...props }) => {
    const { msg, open, onExited } = props;
    // const renders = useRef(0);

    return (
        <>
            {/* {console.log(renders.current++)} */}
            <Dialog
                open={open}
                onTransitionExited={onExited}>
                <DialogContent style={{ overflow: 'hidden' }}>
                    <LoadingPanel msg={msg} />
                </DialogContent>
            </Dialog>
        </>
    )

}

LoadingDialog.propTypes = {
    msg: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onExited: PropTypes.func,
};

LoadingDialog.displayName = 'LoadingDialog';

export default React.memo(LoadingDialog);